import React from 'react';
import FadeIn from '../components/atoms/FadeIn';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Announcements from '@containers/Announcements';
import brand from '@lib/brand';

const Login = dynamic(() => import('../containers/Login'), { ssr: false });
function LoginPage() {
  return (
    <FadeIn>
      <Head>
        <title>{brand.brand} | Login</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Announcements />
      <Login />
    </FadeIn>
  );
}

export default LoginPage;
